import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

Vue.use(VueRouter);

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err)
}

// 0 超级管理员
// 1 管理员
// 2 题库管理员
// 4 老师
// 5 校长

export const routes = [
    {
        path: "/login",
        name: "login",
        component: () => import("../views/Login.vue"),
    },
    {
        path: "/",
        name: "home",
        redirect: "/testpaper",
        component: () => import("../views/HomeView.vue"),
        meta: {
            title: "首页",
        },
        children: [
            {
                path: "testpaper",
                name: "testpaper",
                component: () => import("../views/TestPaper.vue"),
                meta: {
                    title: "试卷管理",
                },
            },
            {
                path: "administrators",
                name: "administrators",
                component: () => import("../views/Administrators.vue"),
                meta: {
                    title: "管理员管理",
                    role: "0,1"
                },
            },
            {
                path: "teacher",
                name: "teacher",
                component: () => import("../views/Teacher.vue"),
                meta: {
                    title: "教师管理",
                    role: "0,1,5"
                },
            },
            // {
            //     path: "student",
            //     name: "student",
            //     component: () => import("../views/Student.vue"),
            //     meta: {
            //         title: "学生管理",
            //         role: "0,1,4,5"
            //     },
            // },
            // {
            //     path: "campus",
            //     name: "campus",
            //     component: () => import("../views/Campus.vue"),
            //     meta: {
            //         title: "校区管理",
            //         role: "0,1,5"
            //     },
            // },
            // {
            //     path: "printlog",
            //     name: "printlog",
            //     component: () => import("../views/PrintLog.vue"),
            //     meta: {
            //         title: "打印记录",
            //         role: "0,1,2,5"
            //     },
            // },
            // {
            //     path: "examination",
            //     name: "examination",
            //     component: () => import("../views/Examination.vue"),
            //     meta: {
            //         title: "考试记录",
            //         role: "0,1,2,5"
            //     },
            // },
            {
                path: "log",
                name: "log",
                component: () => import("../views/LogInformation.vue"),
                meta: {
                    title: "日志信息",
                    role: "0,1,2,5"
                },
            }
        ],
    }
];

const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes,
});

//路由守卫
router.beforeEach((to, from, next) => {
    // if(to.meta.role === undefined ?  false : !to.meta.role.split(',').includes(store.state.role + '')){
    //     next("/login");
    //     return
    // }
    if (to.meta.title) { //拦截后设置标题
        document.title = `百日通 - ${to.meta.title}`
    }
    if (to.path === "/login") {
        //若要跳转的页面是登录界面
        next(); //直接跳转
    } else if (from.path === "/") {
        //若要跳转的页面是个人界面
        let token = sessionStorage.getItem("token"); //获取本地存储的token值
        if (token === null || token === "") {
            //若token为空则验证不成功，跳转到登录页面
            next("/login");
        } else {
            //不为空则验证成功
            next();
        }
    } else {
        next();
    }
});

export default router;