import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    role: "",
    token: "",
    username: "",
    admin_id: "",
  },
  getters: {},
  mutations: {
    //用户注销
    loginOut(state) {
      state.token = "";
    },
    setToken(state, data) {
      state.token = data;
    },
    setUserName(state, data) {
      state.username = data;
    },
    setAdminId(state, data) {
      state.admin_id = data;
    },
    setRole(state, data) {
      state.role = data;
    },
  },
  actions: {
    loginOut(ctx) {
      ctx.commit("loginOut");
    },
    setUserInfo(ctx, data) {
      ctx.commit("setUserInfo", data);
    },
  },
  modules: {},
});
