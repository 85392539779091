<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  created() {
    //在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem("role")) {
      this.$store.commit("setRole", sessionStorage.getItem("role"));
    }

    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("role", this.$store.state.role);
    });

    if (sessionStorage.getItem("admin_id")) {
      this.$store.commit("setAdminId", sessionStorage.getItem("admin_id"));
    }

    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("admin_id", this.$store.state.admin_id);
    });

    //在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem("username")) {
      this.$store.commit("setUserName", sessionStorage.getItem("username"));
    }

    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("username", this.$store.state.username);
    });

    if (sessionStorage.getItem("token")) {
      this.$store.commit("setToken", sessionStorage.getItem("token"));
    }

    // 在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("token", this.$store.state.token);
      // window.sessionStorage.clear();
    });
    window.sessionStorage.removeItem("username");
    window.sessionStorage.removeItem("role");
  },
};
</script>

<style lang="scss">
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
}
</style>
